<template>
  <div v-if="systemNotifications.length">
    <UiNotificationBar
      v-for="systemNotification in systemNotifications"
      :key="systemNotification.id"
      :message="systemNotification.message"
      :type="systemNotification.type"
      :closable="systemNotification.closable"
      :timeout="systemNotification.timeout"
      :background-color="isCustomNotification ? notificationBackground : null"
      @click:close="closeNotificationBar(systemNotification.id)"
    />
  </div>
</template>

<script lang="ts" setup>
import UiNotificationBar from '@ui/components/UiNotificationBar/UiNotificationBar.vue'
import type { NotificationBackgroundType } from '@ui/composables/useNotificationsUi'
import { useNotification } from '../../composables/useNotifications'
import type { NotificationComponentType } from '../../stores/notifications.types'
import { useRegionalCookie } from '../../composables/useRegionalStorage'

const { initialData } = useT3Api()

const _componentType: NotificationComponentType
  = 'system' as NotificationComponentType

const { systemNotifications, closeNotification, showNotification }
  = useNotification(_componentType)

const notificationMessage = computed(() => initialData.value?.toolbarMessage?.bodytext || '')

const notificationMaxAge = computed(() => initialData.value?.toolbarMessage?.showAgainAfter || undefined)

const notificationBackground = computed<NotificationBackgroundType>(() => {
  return initialData.value?.toolbarMessage?.background || 'red'
})

const isCustomNotification = computed(() => {
  return !!(
    initialData.value?.toolbarMessage?.background && initialData.value.toolbarMessage.background !== 'red'
  )
})

const setSystemNotificationCookie = useRegionalCookie('system-notification', {
  maxAge: notificationMaxAge.value,
})

const systemNotificationCookie = useRegionalCookie('system-notification').value

function closeNotificationBar(notificationId: string) {
  if (notificationMaxAge.value) {
    setSystemNotificationCookie.value = 'hidden'
  }

  closeNotification(notificationId)
}

onMounted(() => {
  if (notificationMessage.value && !systemNotificationCookie) {
    showNotification({
      id: 'system-notification',
      message: notificationMessage.value,
      type: isCustomNotification.value ? 'message' : 'error',
      closable: notificationMaxAge.value !== 0,
    })
  }
})
</script>
